<template>
    <div class="header header_main">
        <div class="header__container container">
            <template v-if="headerData">
                <div class="header__left">
                    <div class="header__logo">
                        <img  :src="headerData.portal_dealer.logo_url || '/images/header/logo.svg'" alt="logo">
                    </div>
                    <div class="header__desktop">
                      <Nav :items="leftNavItems" :key="leftNavItems" v-if="isAuth"/>
                    </div>
                </div>
                <div class="header__right header__desktop">
                      <Nav  :items="rightNavItems" :key="rightNavItems"  @about="about"  @logout="logout" v-if="isAuth"/>
                      <div class="contact-us">Contact Us Today
                      <a :href="`tel:${headerData.portal_dealer.phone}`" class="contact-us__phone"><svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.3884 1H1.6066C1.18051 1 0.77186 1.16791 0.470563 1.4668C0.169267 1.76568 0 2.17106 0 2.59375L0 14.2812C0 14.7039 0.169267 15.1093 0.470563 15.4082C0.77186 15.7071 1.18051 15.875 1.6066 15.875H13.3884C13.8145 15.875 14.2231 15.7071 14.5244 15.4082C14.8257 15.1093 14.995 14.7039 14.995 14.2812V2.59375C14.995 2.17106 14.8257 1.76568 14.5244 1.4668C14.2231 1.16791 13.8145 1 13.3884 1ZM12.8398 11.2056L12.3377 13.3638C12.3122 13.4736 12.25 13.5715 12.1612 13.6416C12.0724 13.7117 11.9622 13.75 11.8487 13.75C6.49335 13.75 2.14214 9.44322 2.14214 4.12109C2.14612 4.00955 2.18609 3.90222 2.25616 3.81489C2.32624 3.72757 2.42272 3.66486 2.5314 3.636L4.70701 3.13795C4.74412 3.13016 4.78189 3.12583 4.81981 3.125C4.9169 3.12986 5.0109 3.16041 5.09207 3.21349C5.17323 3.26656 5.2386 3.34022 5.28137 3.42682L6.2855 5.75104C6.31009 5.8137 6.32379 5.88005 6.326 5.94727C6.31782 6.0947 6.25174 6.23309 6.14191 6.33275L4.8737 7.36205C5.64213 8.97769 6.95294 10.278 8.5816 11.0403L9.6192 9.78223C9.71967 9.67328 9.85917 9.60772 10.0078 9.59961C10.0756 9.60177 10.1424 9.61536 10.2056 9.63978L12.5486 10.6359C12.6359 10.6783 12.7102 10.7431 12.7637 10.8236C12.8172 10.9041 12.848 10.9974 12.8528 11.0938C12.8522 11.1314 12.8478 11.1689 12.8398 11.2056Z" />
                      </svg>
                      <span>{{ formatText( headerData.contact_phone) }}</span></a>
                      </div>
                </div>
              <div
                  class="header__trigger"
                  v-if="isAuth"
                  :class="menuShow && 'header__trigger--active'"
                  @click="menuShow =!menuShow">
                <span v-if="menuShow" ><i class="fas fa-times"></i></span>
                <span v-else> <i class="fas fa-bars"></i> </span>
              </div>
                <div class="header__mobile"
                    :class="menuShow && 'header__mobile--active'">
                  <div class="header__mask"></div>
                  <div>
                    <Nav :items="leftNavItems" :key="leftNavItems"  @close="menuShow = false"/>
                    <Nav :items="rightNavItems" :key="rightNavItems" @close="menuShow = false" @about="about"  @logout="logout"/>
                  </div>
                </div>
            </template>
        </div>
        <div id="page-header"></div>

      <Modal v-if="aboutShow" v-model="aboutShow">
        <AboutModal />
      </Modal>
    </div>
</template>

<script>
import Nav from './header/Nav'
import AboutModal from "@/components/about/AboutModal";
import Modal from "@/components/elements/Modal";
import { ref, computed} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import api from '@/services/auth/auth.service.js'
import {formatText} from "./../../helpers/formatter";

export default ({
    components: {
        Nav,
      AboutModal,
      Modal
    },
    setup() {
        const store  = useStore()
        const router = useRouter()

        const menuShow = ref(false)
        const aboutShow = ref(false)
        const headerData = computed(() => store.state.main.settings)

        const isAuth = computed(() => store.state.main.isAuth)

        const leftNavItems = [
            {
                title: 'MY ESTIMATES',
                href: '/my-estimates',
            },
            {
                title: 'NEW ESTIMATE',
                href: '/create',
            },
        ]

        const rightNavItems = [
            {
                title: 'MY ACCOUNT',
                href: '/account',
            },
            {
                title: 'LOGOUT',
                href: '',
                event: 'logout'
            },
            {
              title: 'About Us',
              href: '',
              event: 'about'
            }
        ]

        async function logout() {
            const result = await api.logout()
            localStorage.removeItem('token')
            router.push('/login')
        }
        async function about() {
          aboutShow.value = true
        }

        return {
            leftNavItems,
            rightNavItems,
            headerData,
            isAuth,
            logout,
            about,
            aboutShow,
            menuShow,
            formatText,
        }
    },
})
</script>


<style scoped lang="scss">
::v-deep .modal {
  width: calc(100% - 60px);
  max-width: 840px;
  height: calc(100% - 60px);
  max-height: 1000px;
  padding: 0;
  @media(max-width: 768px) {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    max-height: none;
  }

}
.header {
 /* @media(max-width: 768px) {
    position: sticky;
  }*/
    height: 50px;
    background: var(--PrimaryColour);
    // position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    &__logo {
        width: 161px;
       // height: 39px;
        margin-right: 131px;
        display: flex;
      height: auto!important;
      @media(max-width:768px) {
        padding-left:8px;
      }
        img {
            max-height: 39px;
        }
        @media(max-width: 1100px) {
            margin-right: 31px;
        }
    }
    &__right, &__left {
        display: flex;
        align-items: center;
    }
    &__mobile {
      display: none;
    }
      &__trigger {
        display: none;
      }
    .contact-us {
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        color: var(--SecondaryColour);
        margin-left: 12px;
        &__phone {
            display: flex;
            color: var(--PrimaryTextColour);
            cursor: pointer;
            text-decoration: none;
            svg {
                fill: var(--SecondaryColour);
                transform: translateY(3px);
                margin-right: 5px;
            }
        }
    }

}
@media(max-width: 768px) {
  ::v-deep .modal {
    max-width: 610px;
    width: 100%;
  }
  .header {
    /*&__container {
      justify-content: center!important;
    }*/
    &__mobile {
      display: none;
      &--active {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 999;
        top: 100%;
        width: 210px;
        right: 0;
        color: var(--SecondaryColour);
        .header__mask {
          position: fixed;
          top: 50px;
          left: 0;
          width: 100%;
          height: calc(100vh - 50px);
          background: rgba(28, 40, 51, 0.6);
          display: flex;
          transition: opacity 0.3s ease;
        }
        >div {
          background-color: white;
          flex-direction: column;
          z-index: 1;
          margin: 0;
          text-align: end;
          width: 100%;
        }
      }
    }
    &__desktop {
      display: none;
    }
    &__trigger {
      display: flex;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: white;
      font-size: 17px;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 20px;
      background: var(--SecondaryColour);
      transition: background-color 0.2s;
      &--active {
        font-size: 23px;
      }
    }
  }
}
</style>
