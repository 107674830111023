import { AJAX_GET, AJAX_POST } from '@/helpers/ajax.js'

export default {
    async getBrandingSettings() {
        return await AJAX_GET('/branding-settings', {}, 'noAuth')
    }, 
    async register(payload) {
        return await AJAX_POST('/register', payload, 'noAuth') 
    },
    async verifyEmail(url,payload) {
        return await AJAX_POST(url, payload) 
    },
    async authSocial(platform, access_token) {
        return await AJAX_POST(`/social/${platform}`, {access_token}, 'noAuth')
    },
    async login(payload) {
        return await AJAX_POST('/login', payload, 'noAuth')
    },
    async logout(payload) {
        return await AJAX_POST('/logout', payload)
    },
    async setPassword(payload) {
        return await AJAX_POST('/reset-password', payload)
    },
    async resetPassword(payload) {
        return await AJAX_POST('/forgot-password', payload, 'noAuth')
    },
    
}