

export default {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    getters: {
        showPrice: (state, getters) => (area) => {
            return true //area === 'my-orders'? getters.can('see cost'): getters.can('see price')
        },
        canSeePrice: ()  => {
            return true
        },
        canSeeCost: ()  => {
            return false
        }
    }
}