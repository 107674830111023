import { AJAX_GET, AJAX_POST } from '@/helpers/ajax.js'

export default {
    async getAboutDetails() {
        return await AJAX_GET('/about-us', '', true)
    },
    async getAboutArea() {
        return await AJAX_GET('/area-of-services', '', true)
    },
}

