<template>

  <div class="main">
    <div class="sub">
      <div class="about__header" v-show="loading >0">{{about.business?.trading_name}}</div>
        <div class="about" v-show="loading >0">
      <div class="about__body">
        <div class="about__business">
          <img width="100%" class="tw-w-full" :src="about.business?.logo_url"/>
          <div><strong>Business name:</strong> {{about.business?.business_name}}</div>
          <div><strong>ABN:</strong> {{about.business && about.business.abn ? about.business.abn : 'N/A'}}</div>
          <div><strong>ACN:</strong> {{about.business && about.business.acn ? about.business.acn : 'N/A'}}</div>
        </div>
        <div class="about__subheader">Contact Details</div>
        <div class="about__contact" v-if="about.contact?.address">
          <div>
            <div class="tw-font-bold">Address:</div>
            <div>{{about.contact.address?.address}}</div>
            <p>{{about.contact?.address?.suburb}}</p>
            <p>{{about.contact?.address?.postcode}}</p>
            <div>{{about.contact?.address?.state?.name}} {{about.contact.address?.state?.state_id}} {{about.contact?.address?.state?.code}}</div>
          </div>
          <div>
            <div><strong>Phone:</strong> {{formatText( about.contact?.phone)}}</div>
            <div><strong>Email:</strong> {{about.contact?.email}}</div>
          </div>
        </div>
        <div class="about__contact">
<!--          <div>
            <div class="about__subheader">Service Area</div>
            <div>
              <GMapMap
                  :options="{draggingCursor: 'default', streetViewControl: false, componentRestrictions: { country: 'aus' },fields: ['address_components']}"
                  :center="{lat: -27.595454240977247, lng: 153.0383028085131}"
                  :zoom="10"
                  :data="geolocations"
                  map-type-id="terrain"
                  class="about__map"
                  ref="myMapRef"
              >
              </GMapMap>
            </div>

          </div>-->
          <div class="tw-hidden">
            <div class="about__subheader">Licences</div>
            <div v-if="about && about.licences && about.licences.length > 0" >
              <div v-for="(licence,index) in about.licences" :key="index">
                {{licence.licence_type}} -  {{licence.licence_number}}
              </div>
            </div>
            <div v-else>
              N/A
            </div>
          </div>
        </div>
        <div>
          <div class="about__subheader tw-font-bold">Service Area</div>
          <div class="tw-relative">
            <div v-if="loadingMapCoordinates" class="tw-absolute tw-flex tw-items-center tw-justify-center tw-inset-0 tw-z-50 tw-bg-white">
              <span class="tw-animate-pulse">Loading...</span>
            </div>
            <GMapMap
                :options="{draggingCursor: 'default', streetViewControl: false, componentRestrictions: { country: 'aus' },fields: ['address_components']}"
                :center="center"
                :zoom="6"
                :data="geolocations"
                map-type-id="terrain"
                class="about__map"
                ref="myMapRef"
            >
            </GMapMap>
          </div>

        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed, onMounted, watch } from 'vue'
import Button from '@/components/form/Button'
import api from '@/services/about/about.service.js'
import {useStore} from "vuex";
import _ from "lodash";
import {formatText} from "./../../helpers/formatter";


export default ({
  components: {
    Button,
  },
  props: ['estimate', 'show', 'summary'],
  setup(props, {emit}) {
    const about = ref({})
    const loading = ref(0)
    const geolocations = ref({})
    const center = ref({lat: -25.595454240977247, lng: 134.0383028085131})
    const loadingMapCoordinates = ref(true)

    onMounted(async () => {
      const result = await api.getAboutDetails()
      const resultApi = await api.getAboutArea()
      if(result) {
        about.value = result.data
        loading.value++
      }
      if(resultApi) {
        geolocations.value = resultApi.data
        loading.value++
      }
    })
    const myMapRef = ref();

    watch([() => about.value, () => myMapRef.value], ([abt, googleMap]) => {
      if(abt?.contact && googleMap) {
        googleMap.$mapPromise.then(map=> {
          const addressObj = abt?.contact?.address
          const addressText = addressObj? `${addressObj.address}, ${addressObj.suburb}, ${addressObj.state.name} ${addressObj.postcode}, Australia`: '';
          const { google } = window;
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ address: addressText }, (results, status) => {
            if (status === 'OK') {
              center.value = {
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
              };
            } else {
              console.log('Geocode was not successful for the following reason: ' + status);
            }
            setTimeout(() => {
              loadingMapCoordinates.value = false
            }, 300);
          });
        })

      }
    
    }, {deep: true, immediate: true});
    watch(() => geolocations.value, (newVal,oldVal) => {
      if(myMapRef.value) {
        myMapRef.value.$mapPromise.then(map=> {
          newVal.forEach(x => {
            if(!map.data.getFeatureById(x.id)) {
              map.data.addGeoJson( _.cloneDeep(x.geojson), {idPropertyName: 'id'} )
            }
          })
        })
        myMapRef.value.$mapPromise.then(map => {
          map.data.setStyle(styleFeature);
        })
      }
    }, {immediate: true})
    const styleFeature = (feature) => {
      const id = feature.getId()
      const isSelected = !!geolocations.value.find(x => x.id === id)
      let outlineWeight = 0.5,
          zIndex = 1;
      if (feature.getProperty("state") === "hover") {
        outlineWeight = zIndex = 2;
      }
      return {
        strokeWeight: outlineWeight,
        strokeColor: "#fff",
        zIndex: 1,
        fillColor: isSelected? 'green': 'rgba(0,0,0,0.5)',
        fillOpacity: 0.75,
        visible: true,
      };
    }
    // const onMaploaded = (map) => {
    //   map.data.setStyle(styleFeature);
    // }
    return {
      loadingMapCoordinates,
      center,
      formatText,
      about,
      loading,
      geolocations,
      myMapRef
    }
  }
})
</script>

<style scoped lang="scss">

.main {
  height: 100%;
  width: 100%
}
.sub{
  width: 100%;
  margin: 0 auto;
  background: #fff;
  padding: 25px 37px;
  position: relative;
  overflow: hidden;
  height: 100%;

}

.about {
  &__body {
    padding: 0 5px;
  }
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(243, 240, 240);
    border-radius: 10px;
  }
  display: block;
  margin-top: 21px;
  overflow-y: scroll;
  height : calc(100% - 60px);
  font-weight: normal;
  text-align: left;
  &__header {
    text-align: center;
    font-size: 24px;
    line-height: 27px;
    text-transform: uppercase;
    color: #000000;
    font-weight: bold;
    font-family: Sansation;
  }
  &__subheader {
    font-family: Sansation;
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    padding: 8px 0;
  }
  &__map {
    height:45vh;
    ::v-deep * {
      cursor: url("https://maps.gstatic.com/mapfiles/openhand_8_8.cur"), default;
    }

  }
  &__contact {
    display: flex;
    >div {
      width: 50%;
      padding-right: 10px;
    }
  }
}
@media (max-width: 768px) {

  .about {
    &__contact {
      display: flex;
      flex-direction: column;
      gap: 10px;
      >div {
        width: 100%;
        padding-right: 0px;
      }
    }
  }
}

@media (max-height: 768px) {

  .about {
    &__contact {
      display: flex;
      >div {
        width: 100%;
        padding-right: 0px;
      }
    }
    &__map {
      height:80vh;

    }
  }
}


</style>
