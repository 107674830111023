<template>
    <ul class="nav">
        <li v-for="(item, index) in items" :key="`${key}-${index}`" class="nav__item" @click="emitEvent(item)">
            <router-link :to="item.href" @click="$emit('close')"> {{ item.title }}</router-link>
        </li>
    </ul>
</template>

<script>
import api from '@/services/auth/auth.service.js'

export default ({
    props: ['items', 'key'],
    emits: ['close'],
    setup(props,{emit}) {
        function emitEvent(item) {
           if('event' in item) emit(`${item.event}`)
        }

        return {
            emitEvent
        }
    },
})
</script>


<style scoped lang="scss">
.nav {
    display: flex;
    &__item, a {
        color: var(--PrimaryTextColour);
        text-decoration: none;
    }
    &__item {
        list-style: none;
        font-weight: bold;
        font-size: 13px;
        font-family: Sansation;
        text-transform: uppercase;
        margin-right: 24px;
        &:last-of-type {
            margin-right: 0;
        }
    }
}
@media(max-width: 768px) {
  .nav {
    flex-direction: column;
    &__item, a {
      color: var(--SecondaryButtonColour);
    }
    &__item {
      padding: 12px 20px;
      margin-right: 0;
      font-size: 13px;
    }
  }
}
</style>