import { setField, setFieldObj } from "@/helpers/store"

export default {
    namespaced: true,
    state: {
      price: null,
    },
    mutations: {
        setField,
    },
    getters: {
      currentPrice(state) {
        let value = 0
        if(Array.isArray(state.price)) {
          state.price.forEach(section => {
            for(const key in section) {
              value += section[key]
            }
          })
        }
        return value
      }
    }
}
