<template>
    <div class="modal-wrapper" :class="{show: modelValue}" >
        <div class="modal">
            <img class="modal__close" src="/images/close.svg" alt="close" @click="$emit('update:modelValue', false)">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { watch } from 'vue'
export default({
    props: ['modelValue'],
    watch: {
        modelValue(value) {
            const body  = document.querySelector('body')
            if(value) body.classList.add('overflow-hidden')
            else body.classList.remove('overflow-hidden')
        }
    },
})
</script>





<style scoped lang="scss">
.modal-wrapper {
    position: fixed;
    overflow: auto;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
  align-items: center;
  justify-content: center;
    top: 0;
    min-width: 100vw;
    max-width: 100vw;
    z-index: -100;
    height: 100%;
    transition: 0.5s ease-in;
    opacity: 0;
    &.show {
        opacity: 1;
        z-index: 1000;
        .modal {
            opacity: 1;
        }
    }
}
.modal {
    padding: 25px;
    border-radius: 2px;
    opacity: 0;
    position: relative;
   max-height: 100%;
    background: #fff;
    min-height: 150px;
    //width: 370px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 17px;
    &__close {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
        z-index: 10;
    }
}

</style>
