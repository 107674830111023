import { setField, setFieldObj } from "@/helpers/store"

export default {
    namespaced: true,
    state: {
      user: null,
      settings: null,
      isAuth: false,
    },
    mutations: {
      setField,
      setFieldObj,
    },
}