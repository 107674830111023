import { createWebHistory, createRouter } from "vue-router"
import store from '@/store'

const routes = [
  {
    path: '/',
    redirect: firstEnter(),
    // beforeEnter: (to, from, next) => {
    //   next(firstEnter())
    // }
  },
  {
    path: "/login",
    name: "login",
    meta: {
      bgPage: '#E5E5E5',
    },
    component: () => import('@/views/auth/login'),
  },
  {
    path: "/auth/:type",
    name: "auth.social",
    meta: {
      bgPage: '#E5E5E5',
      requiresAuth: false,
    },
    component: () => import('@/views/auth/socialAuth'),
  },
  {
    path: "/auth/social-callback/:type/:tempId",
    name: "auth.social.callback",
    meta: {
      bgPage: '#E5E5E5',
      requiresAuth: false,
    },
    component: () => import('@/views/auth/socialAuthCallBack'),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: {
      bgPage: '#E5E5E5',
    },
    component: () => import('@/views/auth/set-password'),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: {
      bgPage: '#E5E5E5',
    },
    component: () => import('@/views/auth/reset-password'),
  },
  {
    path: "/update-email-address",
    name: "update-email-address",
    meta: {
      bgPage: '#E5E5E5',
      requiresAuth: true,
    },
    component: () => import('@/views/auth/upd-email-address'),
  },
  {
    path: "/enter",
    name: "enter",
    meta: {
      bgPage: '#E5E5E5',
      // requiresAuth: true,
    },
    component: () => import('@/views/auth/not-authorized'),
  },
  {
    path: "/register",
    name: "register",
    meta: {
      bgPage: '#E5E5E5',
    },
    component: () => import('@/views/auth/register'),
  },
  {
    path: "/account",
    name: "account",
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/settings/account'),
  },
  {
    path: "/verify-email/:signature/:path",
    name: "verify-email",
    // meta: {
    //   requiresAuth: true
    // },
    component: () => import('@/views/auth/verify-email'),
  },
  {
    path: "/my-estimates",
    name: "dashboard",
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/dashboard'),
  },
  {
      path: '/my-estimates/:id',
      name: 'estimate-item',
      meta: {
        requiresAuth: true
      },
      component: () => import('@/views/estimate'),
  },
  {
    path: '/specification/:type/:id',
    name: 'specification',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/components/item-wizard/components/estimate/specification'),
},
  {
    path: "/create",
    name: "create",
    meta: {
      // requiresAuth: true,
      bgPage: '#E5E5E5',
    },
    component: () => import('@/views/estimate/create'),
  },
  // {
  //   path: "/color",
  //   name: "color",
  //   meta: {
  //     // requiresAuth: true,
  //     bgPage: '#E5E5E5',
  //   },
  //   component: () => import('@/components/Color.vue'),
  // },
  // {
  //   path: "/home",
  //   name: "home",
  //   component: () => import('@/views/home'),
  // },
  // {
  //   path: '*',
  //   redirect: '/dashboard'
  // },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

function firstEnter() {
  console.log('firstEnter............................')
  const token = localStorage.getItem('token')
  if(token) return '/my-estimates'
  return '/enter'
}

function checkVerify(url) {
  if(url.fullPath.includes('email/verify')) return true
}

function setAuthStatus(value) {
  store.commit('main/setField', {
    field: 'isAuth', value,
  })
}

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  const tokenStatus = token ? true : false
  setAuthStatus(tokenStatus)

  if(tokenStatus && to.name === "login") {
    next('/my-estimates')
    return;
  }
  if(checkVerify(to)) {
    next({name: 'verify-email', params: {
      path: to.fullPath,
      signature: to.query.signature
    }})
    return
  }
  if(to.meta.requiresAuth) {
    if(!token)  next('/enter')
    else next()
  } else next()


})


export default router
