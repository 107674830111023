    <template>
    <div class="footer">
        <div id="sticky-footer-ref" class=""></div>
        <div id="sticky-footer" class=""></div>
        <div class="footer__container container">
            <div class="footer__copyright">Powered By © Starline Security 2019 - {{ currentYear }}. All rights reserved.</div>
            <a class="footer__terms">Terms & Conditions </a>
        </div>
    </div>
</template>

<script>
export default ({
    computed: {
        currentYear() {
            return (new Date()).getFullYear()
        }
    }
})
</script>


<style scoped lang="scss">
.footer {
    background: var(--PrimaryColour);
    width: 100%;
    position: fixed;
 // position: inherit!important;
    bottom: 0;
    z-index: 10;
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        text-align: left;
        flex-direction: row;
        gap: 10px;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }
    &__copyright, &__terms {
        font-size: 15px;
        line-height: 100%;
        color: var(--PrimaryTextColour);//rgba(255, 255, 255, 0.7);
        text-align: left;
    }
    &__copyright {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &__terms {
        text-decoration: none;
        border-bottom: 1px solid var(--PrimaryTextColour);
        padding-bottom: 2px;
        width: max-content;
        white-space: nowrap;
    }
}
@media (max-width: 768px){
  .footer {
    &__copyright{
      font-size: 13px;
    }
  }
}
</style>
