import { createStore } from 'vuex'
import main from './modules/main'
import wizard from './modules/wizard'
import { setField } from '@/helpers/store'
import auth from './modules/auth'

export default createStore({ 
    state: {
        loading: false,
    },
    mutations: {
        setField,
    },
    modules: {
        main,
        wizard,
        auth,
    }, 
})