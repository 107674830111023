<template>
    <div>
      <Loader class="main" customClass="big" v-if="loading"/>
      <template v-else>
        <Header />
        <notifications position="top right"/>
        <div class="content" :style="{ 'background-color': $route.meta.bgPage || '#fff' }">
          <Loader class="main" customClass="big" v-if="$store.state.loading"/>
          <router-view  v-show="!$store.state.loading"></router-view>
        </div>
        <Footer />
      </template>
    </div>
</template>

<script>
import Header from '@/components/layout/Header'
import Footer from '@/components/layout/Footer'
import Loader from '@/components/elements/Loader'
import ButtonBack from '@/components/elements/ButtonBack'
import api from '@/services/auth/auth.service.js'
import { useStore } from 'vuex'
import { ref, onMounted } from 'vue'
import InlineSvg from 'vue-inline-svg'
import { defaultStyleColors } from '@/components/item-wizard/store/constant'
import _ from 'lodash'
import { useQueryProvider } from "vue-query";

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Loader,
    ButtonBack,
    InlineSvg
  },
  created() {
    useQueryProvider();
  },
  setup() {
    const store = useStore()
    const loading = ref(true)
    startApp()

    async function startApp() {
      await getBrandingSettings()
      loading.value = false
    }

    async function getBrandingSettings() {
      const data = await api.getBrandingSettings()
      parseStyles(data && data.data.styling_settings || defaultStyleColors)

      if(!data) return
      store.commit('main/setField', {
        field: 'settings', value: data.data,
      })
    }

    function parseStyles(styles) {
      const stylesMerged = _.uniqBy([ ...styles, ...defaultStyleColors], 'setting')

      const root = document.querySelector('html')
      sessionStorage.setItem('branding', JSON.stringify(stylesMerged))
      stylesMerged.forEach(el => {
        root.style.setProperty(`--${el.setting}`, el.value)
      })
    }


    return {
      loading
    }
  }
}
</script>


<style lang="scss">
// @import url('/assets/global.scss');
// @import url('/styles/fonts.css');
@import "assets/scss/main";


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.logo {
  display: inline;
  width: auto;
}

.svg-icon-secondary-colour {
  path {
    fill: var(--LayoutIconSecondary);
  }
}
.svg-icon-primary-colour {
  path {
    fill: var(--LayoutIconPrimary);
  }
}
#app {
  font-family: Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  height: 100vh;
  position: relative;
}
.content {
  background: var(--PrimaryColour);
  // color: $base-color;
  min-height: 100%;
  @media(max-width: 768px) {
  background-color: #fff !important;
  padding-bottom: 28px;
  }
}
.container, .container_content {
  /*padding: 20px 0!important;
  height: calc(100vh - 76px);
  min-height: unset!important;*/
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  /*@media(max-width: 1200px) {
    padding: 12px !important;
  }*/
  @media(max-width: 768px) {
   // padding: 0 20px;
   /* min-height: auto!important;*/
   // padding: 0!important;


  }
  &_content {

    min-height: 100vh;
    padding: 80px 0;
    position: relative;
    @media(max-width: 768px) {
      padding: 55px 8px 20px 8px !important;
    }
  }
}
.main.spinner {
  stroke: rgb(41, 128, 185);
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%,-50%);
}
.link {
  &_dotted {
    // color: rgba(41, 128, 185, 0.7);
    color: var(--SecondaryButtonColour);
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    padding-bottom: 4px;
    border-bottom: 1px dashed var(--SecondaryButtonColour);
    cursor: pointer;
  }
}
.error {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  input {
    border-color: #DB5151!important;
  }
  &.validation_text {
    color:  #DB5151!important;
  }
}
.validation_text {
    position: absolute;
    left: 0;
    bottom: 0;
    color: #DB5151;
}
.overflow-hidden {
  overflow: hidden;
}
@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}
.vue-notification-group {
  z-index: 1001;
}
.link__back {
    color: var(--PrimaryColour);
    text-decoration: none;
    font-size: 15px;
    margin-bottom: 14px;
    display: flex;
    cursor: pointer;
    span {
        padding-bottom: 2px;
        font-weight: bold;
        border-bottom: 1px solid var(--PrimaryColour);
        margin-left: 5px;
        display: block;
    }
}
.btn {
  padding: 10px !important;
  border: 1px solid !important;
}

.wizard .items__container {
  justify-content: space-evenly!important;
  margin-top: 0!important;
}

@media (max-width: 768px) {
  #type .item__icon svg {
    font-size: 80px !important;
  }
  #type .item__title {
    max-width: 75px!important;
    font-size: 13px!important;
  }

  #type .items__container{
    justify-content: space-between !important;
  }

  .items__container .item__title{
    max-width: 140px!important;
    font-size: 13px!important;
  }

  .step__title {
    margin-bottom: 20px;
    font-size: 22px!important;
    padding-top: 0;
  }
  .header__logo{
    margin: 0!important;
  }
}


</style>
